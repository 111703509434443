@import "core/index.scss";
.section.home-offers {
	+ .home-horizontalbanners {
		margin-top: 1.8rem;
	}
	
	.offers-list {
		display: flex;
		flex-direction: flex-start;
		width: calc(100% + 1.4rem);
		margin: 0 -.7rem;
	}

		.list-offer {
			width: 33.3333333%;
			width: calc(100% / 3);
			padding: 0 0.7rem;
			text-align: center;
		}

			.offer-imagewrap {
				padding-bottom: 138.23%;
				position: relative;
			}

			.offer-image {
				border-radius: $radius-general;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			.offer-title {
				@include font-regular;
				display: block;
				margin: .9rem auto 0;
				font-size: 1.2rem;
				line-height: 1.3em;
				max-height: 2.6em;
				overflow: hidden;

				&.placeholder {
					height: 2.6rem;
					width: 90%;
				}

				strong {
					@include font-black;
					display: block;
				}
			}

			.offer-cta {
				display: block;
				font-size: 1rem;
				margin: .3rem auto 0;

				&.placeholder {
					width: 1rem;
					height: 1rem;
				}
			}
}